<template>
  <div class="login-layout">
    <div class="login-header">
      <img
        width="139"
        height="32"
        src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/login/brand.png"
        alt=""
      />
    </div>
    <div class="login-wrap">
      <div class="login-container">
        <div class="login-left">
          <h1 style="font-size:48px;margin-bottom:8px;">货入十二仓，流量滚滚来</h1>
          <h3 style="font-size:32px;">专注为品牌和主播提供全渠道带货服务</h3>
        </div>
        <div class="login-right">
          <Login v-if="showType === 'login'" />
          <Signin v-if="showType === 'signin'" />
          <ChangePw v-if="showType === 'changePw'" />
          <Wechart />
          <LoginLinks class="login-link" :showType="showType" />
          <div class="wechart-login" v-show="showType === 'login'">
            <div class="wechart-title">微信登录</div>
            <img class="wechart-logo" :src="wechartLogo" alt="" @click="wechartLogin" />
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      <div class="footer-container">
        <img
          width="48"
          height="48"
          src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/login/logo2.png"
          alt=""
        />
        <div class="copyright-box">
          <ul>
            <li><h6>友情链接</h6></li>
            <li><a target="_blank" href="ttps://www.kuaishou.com">快手</a></li>
            <li><a target="_blank" href="https://www.douyin.com/">抖音</a></li>
            <li><a target="_blank" href="https://feitang.com">飞螳</a></li>
            <li><a target="_blank" href="http://www.wenzihui.com/">蚊子会</a></li>
          </ul>
          <ul>
            <li><h6>联系我们</h6></li>
            <li><span>杭州市余杭区联创街99号中国青年电商网红村</span></li>
            <li><span>电话：166-5713-7212</span></li>
            <li><span>邮箱：mochan@12cang.com</span></li>
          </ul>
          <ul>
            <li><span>万同集团旗下品牌</span></li>
            <li><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021001882号-1</a></li>
          </ul>
        </div>
        <img
          width="72"
          height="72"
          src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/images/mochan-qrcode.jpg"
          alt=""
        />
      </div>
    </div>
    <FollowWechat :dialogVisible.sync="dialogVisible"></FollowWechat>
  </div>
</template>

<script>
import ChangePw from '@/modules/login/changePw';
import Login from '@/modules/login/login';
import LoginLinks from '@/modules/login/loginLinks';
import Signin from '@/modules/login/signin';
import Wechart from '@/modules/login/wechart';
import { getQueryString } from '@/helper/index.js';
import FollowWechat from '@/modules/login/followWechat';
export default {
  components: { ChangePw, Login, LoginLinks, Signin, Wechart, FollowWechat },
  data: function() {
    return {
      showType: 'login',
      wechartLogo: require('@/assets/home/wechart.png'),
      dialogVisible: false,
    };
  },
  provide() {
    return {
      setShowType: this.setShowType,
      showType: this.showType,
    };
  },
  created() {
    if (getQueryString('wechart') === 'faild') {
      this.dialogVisible = true;
    } else if (getQueryString('token')) {
      this.getToken();
    }
  },
  methods: {
    getToken() {
      this.$post({
        road: 'accountToken',
        data: { token: getQueryString('token') },
        version: 'v0',
      }).then(res => {
        if (res.error_code === 0) {
          this.$store.commit('SET_TOKEN', res.data.token);
          this.$router.push('/select-pool');
        }
      });
    },
    wechartLogin() {
      // process.env.NODE_ENV !== 'production';
      if (process.env.NODE_ENV !== 'production') {
        window.open(
          'https://open.weixin.qq.com/connect/qrconnect?appid=wx053d714c047def06&redirect_uri=http%3A%2F%2Ftestapi.12cang.com%2Ffrontend%2Faccount%2Fcallback&response_type=code&scope=snsapi_login%2Csnsapi_login&state=77c39ee661aa582d15e536b019e88929&connect_redirect=1#wechat_redirect'
        );
      } else {
        window.open(
          'https://open.weixin.qq.com/connect/qrconnect?appid=wx053d714c047def06&redirect_uri=http%3A%2F%2Fapi.12cang.com%2Ffrontend%2Faccount%2Fcallback&response_type=code&scope=snsapi_login%2Csnsapi_login&state=77c39ee661aa582d15e536b019e88929&connect_redirect=1#wechat_redirect'
        );
      }
    },
    setShowType(type) {
      this.showType = type;
    },
  },
};
</script>

<style lang="less">
.wechart-login {
  margin-top: 40px;
  text-align: center;
  .wechart-title {
    &::before {
      content: '';
      display: inline-block;
      height: 1px;
      width: 50px;
      background: #e5e5e5;
      vertical-align: middle;
      margin-right: 25px;
      width: 100px;
    }
    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 50px;
      background: #e5e5e5;
      vertical-align: middle;
      width: 100px;
      margin-left: 25px;
    }
  }
  .wechart-logo {
    margin-top: 14px;
    width: 32px;
    height: 32px;
  }
}
.login-header {
  width: 1080px;
  margin: 0 auto;
}
.login-header {
  padding: 16px 0;
}
.login-header img {
  display: block;
}
.login-footer {
  padding: 32px 0 40px 0;
  background-color: #f3f3f3;
}
.footer-container {
  margin: 0 auto;
  width: 1080px;
  display: flex;
}
.copyright-box {
  margin: 0 24px;
  flex: 1;
  ul {
    display: flex;
    color: #afafaf;
    margin-bottom: 24px;
    li {
      line-height: 22px;
      margin-right: 24px;
    }
    h6 {
      font-size: 16px;
      color: #000;
    }
  }
}
.login-wrap {
  height: 600px;
  background: url('https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/login/login-bg.png') center
    center;
  background-size: 1920px 600px;
}
.login-container {
  margin: 0 auto;
  padding: 64px 0;
  width: 1080px;
  display: flex;
}

.login-left {
  flex: 1;
  color: #fff;
}

.login-right {
  background: #fff;
  width: 384px;
  height: 472px;
  padding: 0 32px;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ms-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: center;
}

.login-form {
  .el-form-item {
    margin-bottom: 24px;
  }

  .el-input__inner {
    border-radius: 2px;
  }

  .el-input .el-input__inner {
    height: 40px;
    line-height: 20px;
    padding: 10px 12px;
  }
  .el-form-item__error {
    padding-top: 4px;
  }
  .submit-box.el-form-item {
    margin-bottom: 8px;
  }

  .el-button {
    height: 40px;
  }

  .login-form-button {
    width: 100%;
    font-size: 16px;
  }
  .vercode .el-form-item__content {
    display: flex;
  }
  .vercode-btn.is-plain {
    width: 128px;
    margin-left: 12px;
    color: @color-primary;
  }
}

.login-link {
  margin-top: -16px;
}

.login-links {
  display: flex;
  justify-content: space-between;
  color: @color-text-regular;
  .to-login-box {
    color: @color-primary;
    cursor: pointer;
  }

  .to-changepw {
    text-align: right;
    cursor: pointer;
  }
}
</style>
