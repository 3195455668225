<template>
  <div>
    <div v-if="showType === 'signin'" class="login-links">
      已有账号？
      <span class="to-login-box" @click="setShowType('login')">点击登录 ></span>
    </div>
    <div v-if="showType === 'login'" class="login-links">
      <div>
        还没有账号？
        <span class="to-login-box" @click="setShowType('signin')">点击注册 ></span>
      </div>
      <div class="to-changepw" @click="setShowType('changePw')">
        忘记密码
      </div>
    </div>
    <div v-if="showType === 'changePw'" class="login-links">
      <div>
        已有账号？
        <span class="to-login-box" @click="setShowType('login')">返回登录 ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['setShowType'],
  props: {
    showType: {
      type: String,
    },
  },
};
</script>

<style></style>
