const mixin = {
  data() {
    return {
      countNum: 0,
    };
  },
  computed: {
    countDownText() {
      return this.countNum > 0 ? this.countNum + 's' : '发送验证码';
    },
  },
  methods: {
    handlerCountDown() {
      let countNum = 60;
      this.countNum = countNum;
      if (countNum > 0) {
        const countDown_timer = setInterval(() => {
          if (countNum > 0) {
            countNum--;
            this.countNum = countNum;
          } else {
            clearInterval(countDown_timer);
          }
        }, 1000);
      }
    },
  },
};

export default mixin;
