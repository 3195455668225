<template>
  <div class="wechat-con">
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <img
        class="wechat-img"
        src="https://12cang.oss-cn-hangzhou.aliyuncs.com/guanwang/img/product-pool-qrcode.png"
        alt=""
      />
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    // 圆弧百分比
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less">
.wechat-con {
  .el-dialog__wrapper {
    .el-dialog {
      height: 0px !important;
    }
    .el-dialog__header {
      display: none;
    }
  }
}
.wechat-img {
  position: fixed;
  // width: 30%;
  height: 75%;
  top: 10%;
}
</style>
