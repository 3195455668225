<template>
  <el-form :model="formData" :rules="rules" ref="form" class="login-form">
    <div class="ms-title">商家注册</div>
    <el-form-item prop="mobile">
      <el-input v-model="formData.mobile" name="mobile" placeholder="请输入手机账号"></el-input>
    </el-form-item>
    <el-form-item class="vercode" prop="code">
      <el-input name="code" placeholder="请输入验证码" v-model="formData.code"></el-input>
      <el-button
        class="vercode-btn"
        plain
        :disabled="countNum !== 0 || !mobileReady"
        @click="sendCode"
      >
        {{ countDownText }}
      </el-button>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        show-password
        type="password"
        name="password"
        placeholder="请输入8位或以上数字与字母的组合密码"
        v-model="formData.password"
        @keyup.enter.native="submit"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password_confirm">
      <el-input
        show-password
        type="password"
        name="password_confirm"
        placeholder="请再次输入密码"
        v-model="formData.password_confirm"
        @keyup.enter.native="submit"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button class="login-form-button" :loading="submiting" type="primary" @click="submit">
        立即注册
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import regexp from '@/helper/regexp';
import countDownMixin from '@/mixin/countDown';
export default {
  mixins: [countDownMixin],
  inject: ['setShowType'],
  data() {
    return {
      submiting: false,
      mobileReady: false,
      formData: {
        mobile: '',
        code: '',
        password: '',
        password_confirm: '',
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机账号',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (!regexp.mobile.test(value)) {
                callback(new Error('手机号格式错误'));
              } else {
                if (!this.mobileReady) {
                  callback(new Error('该手机号已注册'));
                } else {
                  callback();
                }
              }
            },
            trigger: 'blur',
          },
          {
            validator: this.accountCheck,
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            validator: function(rule, value, callback) {
              if (!regexp.password.test(value)) {
                callback(new Error('密码格式错误'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        password_confirm: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.formData.password !== value) {
                callback(new Error('两次密码填写不一致'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    accountCheck(rule, value, callback) {
      if (value === undefined) return;
      if (regexp.mobile.test(value)) {
        this.$post({
          road: 'accountCheck',
          data: { mobile: value },
          version: 'v0',
        }).then(res => {
          if (res.error_code === 0) {
            if (!res.data) {
              this.mobileReady = true;
              callback();
            } else {
              this.mobileReady = false;
              callback(new Error('该手机号已注册'));
            }
          }
        });
      } else {
        this.mobileReady = false;
      }
    },
    // 发生验证码
    sendCode() {
      this.$store.dispatch('validateCode', {
        params: { mobile: this.formData.mobile, is_register: 1 },
        onSuccess: this.handlerCountDown,
      });
    },
    // 注册
    submit() {
      this.$refs.form.validate((valid, message) => {
        if (valid) {
          this.submiting = true;
          this.$post({
            road: 'register',
            data: this.formData,
            version: 'v0',
          })
            .then(res => {
              if (res.error_code === 0) {
                this.$message.success('注册成功');
                setTimeout(() => {
                  this.setShowType('login');
                }, 1000);
              }
            })
            .finally(() => {
              this.submiting = false;
            });
        }
      });
    },
  },
};
</script>
