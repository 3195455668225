<template>
  <el-form :model="formData" :rules="rules" ref="form" class="login-form">
    <div class="login-type flex">
      <div :class="{ active: loginType === 'code' }" @click="handleLoginType('code')">
        短信登录
      </div>
      <div :class="{ active: loginType === 'password' }" @click="handleLoginType('password')">
        密码登录
      </div>
    </div>
    <el-form-item prop="mobile">
      <el-input v-model="formData.mobile" name="mobile" placeholder="请输入手机账号"></el-input>
    </el-form-item>
    <el-form-item key="password" v-if="loginType === 'password'" prop="password">
      <el-input
        show-password
        type="password"
        name="password"
        placeholder="请输入密码"
        v-model="formData.password"
        @keyup.enter.native="submit"
      ></el-input>
    </el-form-item>
    <el-form-item key="vercode" v-if="loginType === 'code'" class="vercode" prop="code">
      <el-input name="code" placeholder="请输入验证码" v-model="formData.code"></el-input>
      <el-button
        class="vercode-btn"
        plain
        :disabled="countNum !== 0 || !mobileReady"
        @click="sendCode"
      >
        {{ countDownText }}
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-button class="login-form-button" :loading="submiting" type="primary" @click="submit">
        立即登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import regexp from '@/helper/regexp';
import countDownMixin from '@/mixin/countDown';
export default {
  mixins: [countDownMixin],
  inject: ['setShowType'],
  created() {
    this.rules.password = this.password;
  },
  data() {
    return {
      submiting: false,
      mobileReady: false,
      loginType: 'code',
      formData: {
        mobile: '',
        password: '',
        code: '',
      },
      password: [
        {
          required: true,
          message: '请输入密码',
          trigger: 'blur',
        },
        {
          validator: function(rule, value, callback) {
            if (!regexp.password.test(value)) {
              callback(new Error('密码格式错误'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
      ],
      code: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'blur',
        },
      ],
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机账号',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (!regexp.mobile.test(value)) {
                callback(new Error('手机号格式错误'));
              } else {
                if (!this.mobileReady) {
                  callback(new Error('该手机号尚未注册'));
                } else {
                  callback();
                }
              }
            },
            trigger: 'blur',
          },
          {
            validator: this.accountCheck,
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    handleLoginType(type) {
      this.submiting = false;
      this.loginType = type;
      if (this.loginType === 'password') {
        this.rules.password = this.password;
        delete this.rules.code;
      } else {
        this.rules.code = this.code;
        delete this.rules.password;
      }
    },
    accountCheck(rule, value, callback) {
      if (value === undefined) return;
      if (regexp.mobile.test(value)) {
        this.$post({
          road: 'accountCheck',
          data: { mobile: value },
          version: 'v0',
        }).then(res => {
          if (res.error_code === 0) {
            if (!res.data) {
              this.mobileReady = false;
              callback(new Error('该手机号尚未注册'));
            } else {
              this.mobileReady = true;
              callback();
            }
          }
        });
      } else {
        this.mobileReady = false;
      }
    },
    // 发生验证码
    sendCode() {
      this.$store.dispatch('validateCode', {
        params: { mobile: this.formData.mobile, is_register: 0 },
        onSuccess: this.handlerCountDown,
      });
    },
    // 登录
    submit() {
      this.$refs.form.validate((valid, message) => {
        if (valid) {
          const params = {
            mobile: this.formData.mobile,
            login_type: this.loginType,
          };
          if (this.loginType === 'code') {
            params.code = this.formData.code;
          } else {
            params.password = this.formData.password;
          }
          this.submiting = true;
          this.$post({
            road: 'login',
            data: params,
            version: 'v0',
          })
            .then(res => {
              if (res.error_code === 0) {
                const { uid, token, user_type, type_name, mobile } = res.data;
                this.$store.commit('SET_TOKEN', token);
                this.$store.commit('SET_UID', uid);
                this.$store.commit('SET_USERINFO', { user_type, type_name, mobile });
                if (user_type === 0) {
                  this.$router.push('/not-bind');
                } else {
                  this.$router.push('/');
                }
              }
            })
            .finally(() => {
              this.submiting = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-type {
  padding: 0 40px;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 32px;
  div {
    position: relative;
    padding: 0 20px;
    cursor: pointer;
  }
  div.active {
    color: @color-primary;
    font-weight: 500;
  }
  div:first-child::before {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    height: 16px;
    border-left: 1px solid #d9d9d9;
  }
}
</style>
